import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import { Prompt } from "react-router";

import { useTranslation } from "react-i18next";

import { Button, Typography } from "stories/components";
import { Container, Row, Col } from "stories/layout";
import { Input } from "stories/forms";

import ProfileImgUploader from "stories/components/ProfileImgUploader";

import { useAuthContext } from "contexts/AuthContext";

import { useNotification } from "stories/components/Notification";
import { httpGetMyUser, httpUpdateUser } from "services/users";
import { httpGetEnums } from "services/enums";
import { httpGetEvents } from "services/events";

import Select from "react-select";

import LoaderScreen from "components/LoaderScreen";

import warning from "assets/img/icons/common/circle-warning.svg";
import { Modal } from "stories/components";
var isEqual = require("lodash.isequal");

require("yup-phone");

const MyAccount = (props) => {
  const {
    history,
    t,
    therapyTypes,
    selectedTherapyTypes,
    setSelectedTherapyTypes,
    toWhos,
    selectedToWhos,
    setSelectedToWhos,
    locations,
    selectedLocation,
    setSelectedLocation,
    therapys,
    selectedTherapys,
    setSelectedTherapys,
    filteredTherapyTypes,
    profileImage,
    setProfileImage,
    formHasChanged,
    setFormHasChanged,
  } = props;

  const {
    values,
    initialValues,
    touched,
    errors,
    setErrors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldError,
    isSubmitting,
    submitCount,
    validateForm,
  } = props;

  useEffect(() => {
    if (isEqual(values, initialValues)) {
      setFormHasChanged(false);
    } else {
      setFormHasChanged(true);
    }
  }, [values, initialValues]);

  useEffect(() => {
    if (selectedTherapys === undefined) {
    } else {
      if (setErrors) {
        if (selectedTherapys.length === 0) {
          setFieldError("therapys", "Valitse vähintään yksi vaihtoehto.");
        } else {
          setFieldError("therapys", undefined);
        }
      }
    }
  }, [selectedTherapys]);

  useEffect(() => {
    if (setErrors) {
      if (selectedTherapyTypes.length === 0) {
        setFieldError("therapy_types", "Valitse vähintään yksi vaihtoehto.");
      } else {
        setFieldError("therapy_types", undefined);
      }
    }
  }, [selectedTherapyTypes, therapyTypes]);

  useEffect(() => {
    if (setErrors) {
      if (selectedToWhos?.length === 0) {
        setFieldError("to_who", "Valitse vähintään yksi vaihtoehto.");
      } else {
        setFieldError("to_who", undefined);
      }
    }
  }, [selectedToWhos, toWhos]);

  useEffect(() => {
    if (setErrors) {
      if (selectedLocation?.length === 0) {
        setFieldError("location", "Valitse vähintään yksi vaihtoehto.");
      } else {
        setFieldError("location", undefined);
      }
    }
  }, [selectedLocation, locations]);

  const therapyTypesHandler = (event) => {
    setSelectedTherapyTypes(event);
  };

  const toWhoHandler = (event) => {
    setSelectedToWhos(event);
  };

  const locationHandler = (event) => {
    setSelectedLocation(event);
  };

  const therapyHandler = (event) => {
    setSelectedTherapys(event);
  };

  const getFormikInputProps = useCallback(
    (name) => ({
      name,
      value: values[name],
      error: Boolean((touched[name] || submitCount > 0) && errors[name]),
      errorText: touched[name] || submitCount > 0 ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange,
    }),
    [values, errors, touched, handleBlur, handleChange, submitCount]
  );

  const handleCancel = () => {
    history.push("/dashboard/users/list");
  };

  useEffect(() => {
    console.log("therapys", therapys);
  }, [therapys]);

  return (
    <>
      <Container className="mt-4 saoy_min_height">
        <Row>
          <Col className="mt-6">
            {/* {values?.therapist &&
            <Row>
              <Col className="mb-2">
                <Typography variant={"h3"} color={"text-success"}>Vahvistettu käyttäjä &nbsp;<img src={verifiedUser} alt="Vahvistettu käyttäjä" height="25px"/></Typography>
              </Col>
            </Row>
          } */}

            {!values?.therapist && (
              <Row>
                <Col className="mb-2">
                  <Typography variant={"h3"} color={"text-danger"}>
                    Tilisi odottaa hyväksyntää.
                  </Typography>
                  <Typography variant={"h4"} color={"text-danger"}>
                    Hyväksymme palveluntarjoajatilin 2 vrk kuluessa.
                  </Typography>
                </Col>
              </Row>
            )}

            <Row className="mt-3">
              <Col className="mb-2">
                <Typography variant={"h3"} color={"text-dark"}>
                  Oma palveluprofiilin tiedot
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col className="mb-3">
                <Input
                  {...getFormikInputProps("business_id")}
                  label={t("Y-tunnus *")}
                />
              </Col>
            </Row>
            <Row>
              <Col className="mb-3">
                <Input
                  {...getFormikInputProps("company_name")}
                  label={t("Yrityksen nimi *")}
                />
              </Col>
            </Row>

            <Row>
              <Col className="mb-3">
                <Input
                  {...getFormikInputProps("first_name")}
                  label={t("Etunimi *")}
                />
              </Col>
            </Row>

            <Row>
              <Col className="mb-3">
                <Input
                  {...getFormikInputProps("last_name")}
                  label="Sukunimi *"
                />
              </Col>
            </Row>

            <Row>
              <Col className="mb-3">
                <Input {...getFormikInputProps("phone")} label="Puhelin *" />
              </Col>
            </Row>

            <Row>
              <Col className="mb-3">
                <Input
                  {...getFormikInputProps("email")}
                  label="Sähköposti (Käyttäjätunnus)"
                  type="email"
                  disabled
                />
              </Col>
            </Row>

            <Row>
              <Col className="mb-3">
                <Input
                  {...getFormikInputProps("street_address")}
                  label="Katuosoite *"
                  type="text"
                />
              </Col>
            </Row>
            <Row>
              <Col className="mb-3">
                <Input
                  {...getFormikInputProps("postal_code")}
                  label="Postinumero *"
                  type="text"
                />
              </Col>
            </Row>
            <Row>
              <Col className="mb-3">
                <Input
                  {...getFormikInputProps("city")}
                  label="Postitoimipaikka *"
                  type="text"
                />
              </Col>
            </Row>
            <Row>
              <Col className="mb-3">
                <Input
                  {...getFormikInputProps("country")}
                  label="Maa *"
                  type="text"
                />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col>
                <Typography variant="p" bold>
                  Opiskelija
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col className="mb-3">
                <label>
                  Psykoterapeuttiopiskelija
                  <input
                    className="ml-2"
                    type="checkbox"
                    {...getFormikInputProps("psychotherapist_student")}
                    checked={values?.psychotherapist_student}
                  />
                </label>
              </Col>
              <Col>
                <label>
                  Lyhytterapeuttiopiskelija
                  <input
                    className="ml-2"
                    type="checkbox"
                    {...getFormikInputProps("short_term_therapy_student")}
                    checked={values?.short_term_therapy_student}
                  />
                </label>
              </Col>
            </Row>

            <Row className="my-4">
              <Col>
                <Typography className="pb-2" variant={"p"} bold>
                  Terapia
                </Typography>
                <Select
                  name="therapys"
                  placeholder={"Valitse ja lisää"}
                  isMulti={true}
                  value={selectedTherapys}
                  options={therapys}
                  getOptionLabel={(option) => `${option["value"]}`}
                  getOptionValue={(option) => `${option["key"]}`}
                  onChange={therapyHandler}
                />
                {errors && "therapys" in errors && errors["therapys"] && (
                  <span className="text-danger">{errors["therapys"]}</span>
                )}
              </Col>
            </Row>

            <Row className="my-4">
              <Col>
                <Typography className="pb-2" variant={"p"} bold>
                  Terapiamuodot
                </Typography>
                <Select
                  name="therapy_types"
                  placeholder={"Valitse ja lisää"}
                  isMulti={true}
                  value={selectedTherapyTypes}
                  options={filteredTherapyTypes?.sort((a, b) =>
                    a.value.localeCompare(b.value)
                  )}
                  getOptionLabel={(option) => `${option["value"]}`}
                  getOptionValue={(option) => `${option["key"]}`}
                  onChange={therapyTypesHandler}
                />
                {errors &&
                  "therapy_types" in errors &&
                  errors["therapy_types"] && (
                    <span className="text-danger">
                      {errors["therapy_types"]}
                    </span>
                  )}
              </Col>
            </Row>

            <Row className="my-4">
              <Col>
                <Typography className="pb-2" variant={"p"} bold>
                  Valitse kenelle
                </Typography>
                <Select
                  name={"to_who"}
                  placeholder={"Valitse ja lisää"}
                  isMulti={true}
                  value={selectedToWhos}
                  options={toWhos}
                  getOptionLabel={(option) => `${option["value"]}`}
                  getOptionValue={(option) => `${option["key"]}`}
                  onChange={toWhoHandler}
                />
                {errors && "to_who" in errors && errors["to_who"] && (
                  <span className="text-danger">{errors["to_who"]}</span>
                )}
              </Col>
            </Row>

            <Row className="my-4">
              <Col>
                <Typography className="pb-2" variant={"p"} bold>
                  Valitse sijainti
                </Typography>
                <Select
                  name={"location"}
                  placeholder={"Valitse sijainti"}
                  isMulti={true}
                  value={selectedLocation}
                  options={locations}
                  getOptionLabel={(option) => `${option["value"]}`}
                  getOptionValue={(option) => `${option["key"]}`}
                  onChange={locationHandler}
                />
                {errors && "location" in errors && errors["location"] && (
                  <span className="text-danger">{errors["location"]}</span>
                )}
              </Col>
              <Col>
                <Typography className="pb-2" variant={"p"} bold>
                  Tarkenne
                </Typography>
                <Input
                  {...getFormikInputProps("whereabouts")}
                  placeholder={"Esim: Keskusta"}
                  style={{ height: "38px" }}
                />
              </Col>
            </Row>

            <Row>
              <Col className="mt-4">
                <Typography className="pb-2" variant={"p"} bold>
                  Muu vastaanotto
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col className="mb-4">
                <label>
                  Etävastaanotto
                  <input
                    className="ml-2"
                    type="checkbox"
                    {...getFormikInputProps("remote_appointment")}
                    checked={values?.remote_appointment}
                  />
                </label>
              </Col>
              <Col className="mb-4">
                <label>
                  Vain etävastaanotto
                  <input
                    className="ml-2"
                    type="checkbox"
                    {...getFormikInputProps("only_remote_appointments")}
                    checked={values?.only_remote_appointments}
                  />
                </label>
              </Col>
            </Row>

            <Row>
              <Col className="mt-4">
                <Typography className="pb-2" variant={"p"} bold>
                  Korvaukset
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col className="mb-4">
                <label>
                  Kelan hyväksymä
                  <input
                    className="ml-2"
                    type="checkbox"
                    {...getFormikInputProps("kela_approved")}
                    checked={values?.kela_approved}
                  />
                </label>
              </Col>
            </Row>

            <Row>
              <Col className="mb-3">
                <label>
                  Valviran hyväksyntä
                  <input
                    className="ml-2"
                    type="checkbox"
                    {...getFormikInputProps("valvira_approved")}
                    checked={values?.valvira_approved}
                  />
                </label>
              </Col>
              {values?.valvira_approved && (
                <Col className="mb-3">
                  <Input
                    {...getFormikInputProps("julkiterhikki")}
                    // name="valvira"
                    label="JulkiTerhikki-numero"
                    // type="number"
                    placeholder="JulkiTerhikki-numero"
                    style={{ height: "38px" }}
                  />
                </Col>
              )}
            </Row>

            <Row className="mt-3">
              <Col>
                <Typography variant="p" bold>
                  Valitse kielitaito
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col className="mb-3">
                <label>
                  Suomi
                  <input
                    className="ml-3"
                    type="checkbox"
                    {...getFormikInputProps("finnish")}
                    checked={values?.finnish}
                  />
                </label>
              </Col>
              <Col>
                <label>
                  Ruotsi
                  <input
                    className="ml-3"
                    type="checkbox"
                    {...getFormikInputProps("swedish")}
                    checked={values?.swedish}
                  />
                </label>
              </Col>
              <Col>
                <label>
                  Englanti
                  <input
                    className="ml-4"
                    type="checkbox"
                    {...getFormikInputProps("english")}
                    checked={values?.english}
                  />
                </label>
              </Col>
              <Col>
                <label style={{ width: "120px" }}>
                  Muu, mikä?
                  <Input
                    {...getFormikInputProps("other_language")}
                    style={{ height: "38px", width: "120px" }}
                  />
                </label>
              </Col>

              <Col></Col>
              <Col></Col>
            </Row>
          </Col>

          {/***** Profiilikuva **********/}
          <Col className="mt-6 mb-6">
            <Row fluid="true">
              <Col></Col>
              <Col sm="12" md="8" lg="8">
                <ProfileImgUploader
                  profileImage={profileImage}
                  setProfileImage={setProfileImage}
                />
                {/* <CardProfile /> */}
              </Col>
            </Row>
          </Col>
        </Row>

        {/* KOKO LEVEÄNÄ JATKUU */}
        <Row>
          <Col>
            <Row>
              <Col className="my-3">
                <Input
                  {...getFormikInputProps("education")}
                  label="Koulutus *"
                  placeholder="Kerro tähän lyhyesti koulutuksestasi..."
                  type="textarea"
                  rows="4"
                />
              </Col>
            </Row>

            <Row>
              <Col className="my-4">
                <Input
                  {...getFormikInputProps("experience")}
                  label="Kokemus ja osaaminen *"
                  placeholder="Kerro tähän lyhyesti osaamisestasi ja kokemuksesta..."
                  type="textarea"
                  rows="7"
                />
              </Col>
            </Row>

            <Row>
              <Col className="my-4">
                <Input
                  {...getFormikInputProps("what_kind_of_therapist_i_am")}
                  label="Millainen terapeutti olen? *"
                  placeholder="Kerro tähän lyhyesti kuka sinä olet..."
                  type="textarea"
                  rows="7"
                />
              </Col>
            </Row>

            <Row>
              <Col className="my-4">
                <Input
                  {...getFormikInputProps("session_content")}
                  label="Mitä istuinnoillani tehdään? *"
                  placeholder="Lisätietoja voit kirjoittaa tähän..."
                  type="textarea"
                  rows="5"
                />
              </Col>
            </Row>

            <Row>
              <Col className="my-4">
                <Input
                  {...getFormikInputProps("description")}
                  label="Lisätiedot"
                  placeholder="Lisätietoja voit kirjoittaa tähän..."
                  type="textarea"
                  rows="5"
                />
              </Col>
            </Row>

            <Row>
              <Col className="my-3">
                <Button onClick={handleCancel} variant="danger">
                  <Typography bold className="text-uppercase">
                    Peruuta
                  </Typography>
                </Button>
              </Col>
              <Col className="my-3 text-center"></Col>
              <Col className="my-3 text-right">
                <Button
                  variant="success"
                  disabled={isSubmitting || Object.keys(errors).length > 0}
                  onClick={() => validateForm().then(() => handleSubmit())}
                >
                  <Typography bold className="text-uppercase">
                    Tallenna
                  </Typography>
                </Button>
                {Boolean(errors["general"]) && (
                  <Typography className="text-danger">
                    {errors["general"]}
                  </Typography>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Prompt
        when={formHasChanged}
        message={t(
          "unsaved_changes",
          "Sinulla on tallentamattomia tietoja, poistutaanko silti?"
        )}
      />

      {/* <Modal
        isOpen={dirty}
        centered
      >
        <div className="modal-header p-0 mb-3">
          <Typography className="text-black" variant="h3" bold>Ajanvaraus epäonnistui</Typography>
        </div>
        <div className="modal-body p-0">
          <Row>
            <Col>
              <Row className="mt-2">
                <Col>
                  <Typography bold variant={'display5'}>
                    Sinulla on tallentamattomia tietoja, poistutaanko silti?
                  </Typography>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="modal-footer p-0 mt-5 d-flex justify-content-between">
            <Button
              color="default"
              style={{ width: "180px" }}
              // onClick={toggleReturnFailedModal}
            >
              <Typography bold>Sulje</Typography>
            </Button>
        </div>
      </Modal> */}
    </>
  );
};

const defaultValues = {
  id: null,
  name: "",
};

const MyAccountFormik = withFormik({
  validateOnMount: true,
  mapPropsToValues: (props) => {
    const { preSetValues } = props;
    if (preSetValues) {
      return preSetValues;
    } else {
      return defaultValues;
    }
  },
  validationSchema: (props) => {
    const { t } = props;
    const required = t("Kenttä on pakollinen");
    return Yup.object().shape({
      first_name: Yup.string().required(required).nullable(),
      last_name: Yup.string().required(required).nullable(),
      username: Yup.string().required(required).nullable(),
      email: Yup.string()
        .email(t("Tarkista sähköpostiosoitteen muoto"))
        .required(required),
      phone: Yup.string()
        .phone(
          "FI",
          false,
          t("Puhelinnumero tulee olla muodossa esim. +358501234567")
        )
        .required(required)
        .nullable(),
      education: Yup.string().required(required).nullable(),
      experience: Yup.string().required(required).nullable(),
      what_kind_of_therapist_i_am: Yup.string().required(required).nullable(),
      session_content: Yup.string().required(required).nullable(),
      whereabouts: Yup.string().nullable(),
      other_language: Yup.string().nullable(),
      valvira_approved: Yup.boolean(),
      julkiterhikki: Yup.number()
        .positive()
        .integer()
        .nullable()
        .label("Vain numeroita")
        .when("valvira_approved", {
          is: true,
          then: Yup.number().label("Vain numeroita").required(required),
        }),
      street_address: Yup.string().required(required).nullable(),
      postal_code: Yup.string().required(required).nullable(),
      city: Yup.string().required(required).nullable(),
      country: Yup.string().required(required).nullable(),
      company_name: Yup.string().required(required).nullable(),
      business_id: Yup.string().required(required).nullable(),
    });
  },
  handleSubmit: (values, { setSubmitting, setErrors, props }) => {
    const {
      t,
      selectedTherapys,
      selectedTherapyTypes,
      selectedToWhos,
      selectedLocation,
      profileImage,
    } = props;

    const formData = new FormData();
    formData.append("profile_image", profileImage);

    let therapies = [];
    selectedTherapys &&
      selectedTherapys.map((st) => {
        therapies.push(st.key);
      });

    let therapyTypes = [];
    selectedTherapyTypes &&
      selectedTherapyTypes.map((st) => {
        therapyTypes.push(st.key);
      });

    let toWhos = [];
    selectedToWhos &&
      selectedToWhos.map((st) => {
        toWhos.push(st.key);
      });

    let location = [];
    selectedLocation &&
      selectedLocation.map((l) => {
        location.push(l.key);
      });

    const { profile_image, ...rest } = values;

    let data = {
      ...rest,
      therapies: therapies,
      therapy_types: therapyTypes,
      to_who: toWhos,
      cities: location,
    };
    httpUpdateUser("me", formData);
    httpUpdateUser("me", data).then(
      (res) => {
        setSubmitting(false);
        props.setFormHasChanged(false);
        props.notify({
          title: t("Käyttäjä"),
          message: t("Päivitetty onnistuneesti"),
        });
      },
      (error) => {
        setSubmitting(false);
        if (error?.data?.detail) {
          setErrors({
            general: error?.data?.detail,
          });
        } else {
          setErrors({
            general: t(
              "Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen."
            ),
          });
        }
      }
    );
  },
  displayName: "BasicForm",
})(MyAccount);

const MyAccountView = ({ match, history }) => {
  // API requests here
  const { t } = useTranslation();
  const { myUser } = useAuthContext();
  const { isAuthenticated } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState(null);
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const [therapyTypes, setTherapyTypes] = useState([]); // Child of therapys
  const [selectedTherapyTypes, setSelectedTherapyTypes] = useState([]);
  const [toWhos, setToWhos] = useState([]);
  const [selectedToWhos, setSelectedToWhos] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [therapys, setTherapys] = useState(""); // Head therapy
  const [selectedTherapys, setSelectedTherapys] = useState();
  const [filteredTherapyTypes, setFilteredTherapyTypes] = useState([]);
  const [events, setEvents] = useState([]);
  const [profileImage, setProfileImage] = useState("");
  const [formHasChanged, setFormHasChanged] = useState(false);

  useEffect(() => {
    if (isAuthenticated === true) {
      setLoading(true);

      httpGetEnums().then((res) => {
        setTherapys(
          res?.data.users.therapy?.sort((a, b) =>
            a.value.localeCompare(b.value)
          )
        );
        setTherapyTypes(
          res?.data.users.therapy_type?.sort((a, b) =>
            a.value.localeCompare(b.value)
          )
        );
        setToWhos(
          res?.data.users.to_who?.sort((a, b) => a.value.localeCompare(b.value))
        );
        setLocations(
          res?.data.addresses.city?.sort((a, b) =>
            a.value.localeCompare(b.value)
          )
        );
        setLoading(false);
      });
    } else {
      window.location.replace("/auth/login");
    }
  }, []);

  useEffect(() => {
    if (selectedTherapys === undefined) {
    } else {
      let list = [];
      selectedTherapys.map((t) => {
        list = [
          ...list,
          ...therapyTypes.filter((s) =>
            s.therapies.map((the) => the.key).includes(t.key)
          ),
        ];
      });
      list = [...new Set(list)];
      setFilteredTherapyTypes(list);
    }
  }, [selectedTherapys]);

  useEffect(() => {
    if (
      therapyTypes &&
      therapyTypes.length > 0 &&
      toWhos &&
      toWhos.length > 0 &&
      locations &&
      locations.length > 0 &&
      therapys &&
      therapys.length > 0
    ) {
      setLoading(true);

      httpGetMyUser().then((res) => {
        setPreSetValues({
          ...defaultValues,
          ...res?.data,
        });

        if (res.data.therapies === null) {
        } else {
          setSelectedTherapys(
            res?.data.therapies.map((s) => {
              return {
                key: s,
                value: therapys.find((v) => v.key === s).value,
              };
            })
          );
        }

        if (res.data.therapy_types === null) {
        } else {
          setSelectedTherapyTypes(
            res?.data.therapy_types.map((s) => {
              return {
                key: s,
                value: therapyTypes.find((v) => v.key === s).value,
              };
            })
          );
        }

        if (res.data.to_who === null) {
        } else {
          setSelectedToWhos(
            res?.data.to_who.map((t) => {
              return {
                key: t,
                value: toWhos.find((v) => v.key === t).value,
              };
            })
          );
        }

        if (res.data.cities.length === null) {
        } else {
          setSelectedLocation(
            res?.data.cities.map((l) => {
              return {
                key: l,
                value: locations.find((v) => v.key === l).value,
              };
            })
          );
        }
        setLoading(false);
      });
    }
  }, [therapyTypes, toWhos, locations, therapys]);

  useEffect(() => {
    httpGetEvents({ user: preSetValues?.id }).then((res) => {
      setEvents(res?.data);
    });
  }, [preSetValues]);

  const handleChangePassword = () => {
    history.push("/dashboard/myaccount/password");
  };

  const clickCalendarViewHandler = () => {
    history.push(`/dashboard/myaccount/calendar/`);
  };

  const clickPaymentViewHandler = () => {
    history.push(`/dashboard/myaccount/payment/`);
  };

  const clickEventsViewHandler = () => {
    history.push(`/dashboard/myaccount/events/`);
  };

  const copyToClipboard = () => {
    const link = `https://app.terapeuttini.fi/dashboard/profile/${myUser.id}`;
    navigator.clipboard.writeText(link);
    notify({
      place: "br",
      message: "Profiili linkki kopioitu",
      type: "success",
    });
    // alert("Profile link copied to clipboard!");
  };

  // useEffect(() => {
  //   console.log('myUser', myUser)
  // })

  if (loading) {
    return (
      <Container fluid="true" className="saoy_min_height">
        <LoaderScreen />
      </Container>
    );
  } else {
    return (
      <Container className="mt-4 saoy_min_height">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/dashboard/frontpage">
                <b>Etusivu</b>
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {t("Omat tiedot")} / {t("Perustiedot")}{" "}
            </li>
          </ol>
        </nav>

        <div className="d-flex justify-content-between">
          <div>
            <Row>
              <Col>
                <Button
                  className="header_btn_without_border active_color"
                  variant={"secondary"}
                >
                  {myUser?.business_id ? (
                    <Typography className="active_color" variant={"h3"}>
                      Perustiedot
                    </Typography>
                  ) : (
                    <Typography
                      className="d-inline-flex active_color"
                      inline
                      variant={"h3"}
                    >
                      Perustiedot&nbsp;
                      <img src={warning} alt="important" />
                    </Typography>
                  )}
                </Button>
              </Col>
              <Col>
                <Button
                  className="header_btn_without_border"
                  variant={"secondary"}
                  onClick={clickCalendarViewHandler}
                >
                  {events.length > 0 ? (
                    <Typography variant={"h3"}>Kalenteri</Typography>
                  ) : (
                    <Typography className="d-inline-flex" inline variant={"h3"}>
                      Kalenteri&nbsp;
                      <img src={warning} alt="important" />
                    </Typography>
                  )}
                </Button>
              </Col>
              {preSetValues?.therapist && (
                <Col>
                  <Button
                    className="header_btn_without_border"
                    variant={"secondary"}
                    onClick={clickPaymentViewHandler}
                  >
                    {preSetValues?.stripe_account_completed ? (
                      <Typography variant={"h3"}>Maksuasetukset</Typography>
                    ) : (
                      <Typography
                        className="d-inline-flex"
                        inline
                        variant={"h3"}
                      >
                        Maksuasetukset&nbsp;
                        <img src={warning} alt="important" />
                      </Typography>
                    )}
                  </Button>
                </Col>
              )}
              <Col>
                <Button
                  className="header_btn_without_border"
                  variant={"secondary"}
                  onClick={clickEventsViewHandler}
                >
                  <Typography variant={"h3"}>Tapahtumat</Typography>
                </Button>
              </Col>
            </Row>
          </div>
          <div className="d-flex ">
            <Button onClick={copyToClipboard} className="me-2">
              <i className="fas fa-share"></i>&nbsp;
              {t("Jaa profiili")}
            </Button>

            <Button onClick={handleChangePassword}>
              {t("Vaihda salasana")}
            </Button>
          </div>
        </div>
        <MyAccountFormik
          filteredTherapyTypes={filteredTherapyTypes}
          therapys={therapys}
          setTherapys={setTherapys}
          selectedTherapys={selectedTherapys}
          setSelectedTherapys={setSelectedTherapys}
          therapyTypes={therapyTypes}
          setTherapyTypes={setTherapyTypes}
          selectedTherapyTypes={selectedTherapyTypes}
          setSelectedTherapyTypes={setSelectedTherapyTypes}
          toWhos={toWhos}
          setToWhos={setToWhos}
          selectedToWhos={selectedToWhos}
          setSelectedToWhos={setSelectedToWhos}
          locations={locations}
          setLocations={setLocations}
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
          history={history}
          t={t}
          preSetValues={preSetValues}
          notify={notify}
          profileImage={profileImage}
          setProfileImage={setProfileImage}
          formHasChanged={formHasChanged}
          setFormHasChanged={setFormHasChanged}
        />
      </Container>
    );
  }
};

export default MyAccountView;
